export const bankConstants = {

CHART_REQUEST:"CHART_REQUEST",
CHART_SUCCESS:"CHART_SUCCESS",
CHART_FAILURE:"CHART_FAILURE",

    DELETE_NOTIFICATION_REQUEST:"DELETE_NOTIFICATION_REQUEST",
    DELETE_NOTIFICATION_SUCCESS:"DELETE_NOTIFICATION_SUCCESS",
    DELETE_NOTIFICATION_FAILURE:"DELETE_NOTIFICATION_FAILURE",

    GETNOTIFICATION_LIST_REQUEST:"GETNOTIFICATION_LIST_REQUEST",
    GETNOTIFICATION_LIST_SUCCESS:"GETNOTIFICATION_LIST_SUCCESS",
    GETNOTIFICATION_LIST_FAILURE:"GETNOTIFICATION_LIST_FAILURE",


    CREATE_NOTIFICATION_ADMIN_REQUEST:"CREATE_NOTIFICATION_ADMIN_REQUEST",
    CREATE_NOTIFICATION_ADMIN_SUCCESS:"CREATE_NOTIFICATION_ADMIN_SUCCESS",
    CREATE_NOTIFICATION_ADMIN_FAILURE:"CREATE_NOTIFICATION_ADMIN_FAILURE",


    GET_BANK_DETAILS_BY_ADMIN_REQUEST:"GET_BANK_DETAILS_BY_ADMIN_REQUEST",
    GET_BANK_DETAILS_BY_ADMIN_SUCCESS:"GET_BANK_DETAILS_BY_ADMIN_SUCCESS",
    GET_BANK_DETAILS_BY_ADMIN_FAILURE:"GET_BANK_DETAILS_BY_ADMIN_FAILURE",

    ADD_ADMIN_BANK_DETAIL_REQUEST:"ADD_ADMIN_BANK_DETAIL_REQUEST",
    ADD_ADMIN_BANK_DETAIL_SUCCESS:"ADD_ADMIN_BANK_DETAIL_SUCCESS",
    ADD_ADMIN_BANK_DETAIL_FAILURE:"ADD_ADMIN_BANK_DETAIL_FAILURE",

GET_ADMIN_BANK_REQUEST:"GET_ADMIN_BANK_REQUEST",
GET_ADMIN_BANK_SUCCESS:"GET_ADMIN_BANK_SUCCESS",
GET_ADMIN_BANK_FAILURE:"GET_ADMIN_BANK_FAILURE",


UPDATE_BANK_DETAILS_ADMIN_REQUEST:"UPDATE_BANK_DETAILS_ADMIN_REQUEST",
UPDATE_BANK_DETAILS_ADMIN_SUCCESS:"UPDATE_BANK_DETAILS_ADMIN_SUCCESS",
UPDATE_BANK_DETAILS_ADMIN_FAILURE:"UPDATE_BANK_DETAILS_ADMIN_FAILURE",

};
