import {
  ticketConstants
} from '../_constants';

export default function tickets(state = {}, action) {
  switch (action.type) {

    case ticketConstants.CREATE_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case ticketConstants.CREATE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ticketConstants.GET_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        ticketList: action.ticket.data.list,
        ticketTotal: action.ticket.data.total,
        loading: false,
      };
    case ticketConstants.GET_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ticketConstants.UPDATE_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case ticketConstants.UPDATE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ticketConstants.UPDATE_TICKET_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.UPDATE_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case ticketConstants.UPDATE_TICKET_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ticketConstants.DELETE_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.DELETE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case ticketConstants.DELETE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}