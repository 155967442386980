import React from "react";
import Modal from 'react-modal';

export default function CreateUserModal(props) {

  let { userOpenModal, handleHideModal, inputChange, fieldsUser, errorsUser, createUserSubmit } = props;

  return (

    <Modal
      isOpen={userOpenModal}
    >

      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between pb-3">
              <p className="text-2xl font-bold">Add User</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <form autoComplete="off">
              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">userName :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="userName" name="userName" placeholder="UserName"
                    // value={fieldsUser.userName}
                    value={fieldsUser && fieldsUser["userName"] ? fieldsUser["userName"] : ""}

                    type="text" onChange={inputChange} />
                  {errorsUser && errorsUser["userName"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUser["userName"]}
                    </div>
                    : null}
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">mobNo :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["mobNo"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="mobNo" name="mobNo" placeholder="mobNo" value={fieldsUser && fieldsUser["mobNo"] ? fieldsUser["mobNo"] : ""} type="text" onChange={inputChange} />
                  {errorsUser && errorsUser["mobNo"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUser["mobNo"]}
                    </div>
                    : null}
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">email :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["value"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="email" name="email" placeholder="email"  value={fieldsUser && fieldsUser["email"] ? fieldsUser["email"] : ""}  type="text" onChange={inputChange} />
                  {errorsUser && errorsUser["email"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUser["email"]}
                    </div>
                    : null}
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">password :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["value"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="password" name="password" placeholder="password"  value={fieldsUser && fieldsUser["password"] ? fieldsUser["password"] : ""}  type="text" onChange={inputChange} />
                  {errorsUser && errorsUser["password"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUser["password"]}
                    </div>
                    : null}
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">confirmPassword :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["value"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="confirmPassword" name="confirmPassword" placeholder="confirmPassword"  value={fieldsUser && fieldsUser["confirmPassword"] ? fieldsUser["confirmPassword"] : ""}  type="text" onChange={inputChange} />
                  {errorsUser && errorsUser["confirmPassword"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUser["confirmPassword"]}
                    </div>
                    : null}
                </div>
              </div>

              {/* {
                filesDetails ?
                  <>
                    <div className="w-full mt-1 mr-2 ">
                      <img src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : "NA"}
                        alt=" " height="20%" width="20%" />
                    </div>
                  </> :
                  null
              } */}

              <div className="flex flex-wrap w-full mt-2">
                <label htmlFor="image">Upload Image</label>
                <input
                  // style={{ display: 'none' }}
                  id="image"
                  name="image"
                  type="file"
                  // onChange={handleFile}
                  disabled
                />
              </div>


              <div className="w-64 mx-auto mt-4 sm:w-72">
                <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button" onClick={createUserSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>


    </Modal>



  );
}
