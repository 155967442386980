export const appSettingConstants = {

    CREATE_APP_SETTING_REQUEST: 'CREATE_APP_SETTING_REQUEST',
    CREATE_APP_SETTING_SUCCESS: 'CREATE_APP_SETTING_SUCCESS',
    CREATE_APP_SETTING_FAILURE: 'CREATE_APP_SETTING_FAILURE',

    GET_ALL_APP_SETTING_REQUEST: 'GET_ALL_APP_SETTING_REQUEST',
    GET_ALL_APP_SETTING_SUCCESS: 'GET_ALL_APP_SETTING_SUCCESS',
    GET_ALL_APP_SETTING_FAILURE: 'GET_ALL_APP_SETTING_FAILURE',

    GET_APP_SETTING_LIST_REQUEST: 'GET_APP_SETTING_LIST_REQUEST',
    GET_APP_SETTING_LIST_SUCCESS: 'GET_APP_SETTING_LIST_SUCCESS',
    GET_APP_SETTING_LIST_FAILURE: 'GET_APP_SETTING_LIST_FAILURE',

    UPDATE_APP_SETTING_REQUEST: 'UPDATE_APP_SETTING_REQUEST',
    UPDATE_APP_SETTING_SUCCESS: 'UPDATE_APP_SETTING_SUCCESS',
    UPDATE_APP_SETTING_FAILURE: 'UPDATE_APP_SETTING_FAILURE',

    UPDATE_APP_SETTING_STATUS_REQUEST: 'UPDATE_APP_SETTING_STATUS_REQUEST',
    UPDATE_APP_SETTING_STATUS_SUCCESS: 'UPDATE_APP_SETTING_STATUS_SUCCESS',
    UPDATE_APP_SETTING_STATUS_FAILURE: 'UPDATE_APP_SETTING_STATUS_FAILURE',

    DELETE_APP_SETTING_REQUEST: 'DELETE_APP_SETTING_REQUEST',
    DELETE_APP_SETTING_SUCCESS: 'DELETE_APP_SETTING_SUCCESS',
    DELETE_APP_SETTING_FAILURE: 'DELETE_APP_SETTING_FAILURE',

};
