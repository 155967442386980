import {
  sliderConstants
} from '../_constants';

export default function sliders(state = {}, action) {
  switch (action.type) {

    case sliderConstants.CREATE_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.CREATE_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.CREATE_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.GET_SLIDER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.GET_SLIDER_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        sliderList: action.slider.data.list,
        sliderTotal: action.slider.data.total,
        loading: false,
      };
    case sliderConstants.GET_SLIDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.UPDATE_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.UPDATE_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.UPDATE_SLIDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.UPDATE_SLIDER_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.UPDATE_SLIDER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.DELETE_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DELETE_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}