import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import marketReducer from './market.reducer';
import gamesReducer from './games.reducer';
import walletReducer from './wallet.reducer';
import sliderReducer from './slider.reducer';
import ticketReducer from './ticket.reducer';
import appSettingReducer from './appSetting.reducer';
import reportReducer from './report.reducer';
import alertReducer from './alert.reducer';
import bankReducer from './bank.reducer ';
const rootReducer = combineReducers({
  users: usersReducer,
  markets: marketReducer,
  games: gamesReducer,
  wallet: walletReducer,
  sliders: sliderReducer,
  tickets: ticketReducer,
  appSettings: appSettingReducer,
  reports: reportReducer,
  alert: alertReducer,
  bank: bankReducer,
});

export default rootReducer;
