import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './AppSettingManagement.json'
import { appSettingActions, userActions } from '../../_actions';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { FaRegSave } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import Table from '../../components/Atoms/Table/Table';


const AppSettingManagement = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState('');
  const { appSettingList, total, loading } = useSelector(state => state.appSettings);
  const selector = useSelector(state => state);
  // console.log("appSettingList",appSettingList);
  
  const [initialState, setInitialState] = useState({
    _id: '',
    name: '',
    key: '',
    value: '',
  });

  const handleChange2 = (e, element) => {
    console.log(element)
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "YoutubeLink" && e.key === "Youtube") {
          setstate1(e.target.value);
        }
        if (element.key === "Email") {
          setstate2(e.target.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(e.target.value);
        }
        if (element.key === "MobileNumber") {
          setstate4(e.target.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(e.target.value);
        }
      });
    }

  };


  // console.log(initialState)

  useEffect(() => {
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(appSettingActions.getAppSettingList(appsettingReq));

  }, []);


  const handleCreateOpenModal = () => {
    setCreateModal(true);
  }
  const savedatadata = (e) => {
    console.log(e)
  }



  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  }

  // const handleUpdateOpenModal = (data) => {
  //   setFieldsUpdate(data);
  //   setUpdateModal(true);
  // }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(appSettingActions.updateAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setUpdateModal, appsettingPageRefresh));
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(appSettingActions.createAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setCreateModal, setFieldsAppSetting, appsettingPageRefresh));
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsAppSetting(errors);
    return formIsValid;
  };


  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      "id": data._id,
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    confirmAlert({

      title: 'Confirm to disable AppSetting?',
      message: `Are you sure to disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(appSettingActions.updateAppSettingStatus(appsettingIdReq, appsettingPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  // const deleteAppSetting = (data) => {
  //   let appsettingIdReq = {
  //     "id": data._id,
  //   }

  //   let appsettingPageRefresh = {
  //     "keyWord": "",
  //     "pageNo": pageNo,
  //     "size": size
  //   }

  //   confirmAlert({

  //     title: 'Confirm to Delete?',
  //     message: `Are you sure you want  to delete ${data.name}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => dispatch(appSettingActions.deleteAppSetting(appsettingIdReq, appsettingPageRefresh))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }


  const handleFile = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
    } else {
      console.log("No File To Upload!");
    }
  };



  const [state1, setstate1] = useState("")
  const [state2, setstate2] = useState("")
  const [state3, setstate3] = useState("")
  const [state4, setstate4] = useState("")
  const [state5, setstate5] = useState("")
  const [state10, setstate10] = useState("")


  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "YoutubeLink") {
          setstate1(element.value);
        }
        if (element.key === "Email") {
          setstate4(element.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(element.value);
        }
        if (element.key === "MobileNumber") {
          setstate2(element.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(element.value);
        }
        if (element.key === "HowToPlay") {
          setValue(element.value);
        }
        if (element.key === "UPI_IMAGE") {
          setstate10(element.value);
        }
      });
    }
  }, [appSettingList]);

  const saveInfo = async (e) => {
    console.log(e)
    let dataofvalue;
    if (e.key === "UPI_IMAGE") {
      let {users} = selector?selector:{}
      let {fileData} = users?users:{}
      dataofvalue = fileData?fileData:state10;
    }
    if (e.key === "TelegramLink") {
      dataofvalue = state5;
    }
    if (e.key === "YoutubeLink") {
      dataofvalue = state1;
    }
    if (e.key === "Email") {
      dataofvalue = state4;
    }
    if (e.key === "WhatsApp") {
      dataofvalue = state3;
    }
    if (e.key === "MobileNumber") {
      dataofvalue = state2;
    }
    if (e.key === "HowToPlay") {
      dataofvalue = value;
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id
    }
    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh))


  }
let handlemobile=(e)=>{
  e.preventDefault();
  if (e.target.value.length<11){
    setstate2(e.target.value)
  }else{
    return;
  }

}




let {users} = selector?selector:{}
let {fileData} = users?users:{}

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between ">
                        <div>
                          <p className='text-2xl font-bold pb-4'>App Setting Management</p>
                        </div>
                      </sectiion>

                      <div>
                        <div className="relative border shadow flex md:flex-row flex-col bg-white p-4 rounded-md">
                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {


                                  if (element.key === "TelegramLink") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state5} onChange={(e) => setstate5(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                  if (element.key === "MobileNumber") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className=' w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state2} onChange={(e)=>handlemobile(e)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  if (element.key === "WhatsApp") {
                                    return (


                                      <form class="flex w-full justify-start items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state3} onChange={(e) => setstate3(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                 
                                  if (element.key === "Email") {
                                    return (


                                      <form class="flex w-full  justify-start items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium  text-gray-900 ">{element && element.name} </label>
                                          <input value={state4} onChange={(e) => setstate4(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 capitalize text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>



                                      </form>

                                    )

                                  }
                                  if (element.key === "YoutubeLink") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state1} onChange={(e) => setstate1(e.target.value)} type="text" id="text" class="bg-gray-50 pe-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }




                                  // if (element.key === "UPI_IMAGE") {
                                  //   return (


                                  //     <form class="flex  justify-start w-full items-center mt-2 ">

                                  //       <div className='w-full relative'>
                                  //         <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                  //         <input value={state10} onChange={(e) => setstate10(e.target.value)} type="text" id="text" class="bg-gray-50 pe-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                  //         <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                  //       </div>
                                  //       <div>

                                  //       </div>


                                  //     </form>

                                  //   )

                                  // }

                                  if (element.key === "UPI_IMAGE") {
                                    return (
                                      <form className="flex justify-start w-full items-center mt-2">
                                        <div className='w-full relative'>
                                          <img src={fileData?fileData:state10} className=' w-28 h-28'/>
                                          <label htmlFor="image" className="block mb-1 text-sm font-medium text-gray-900">
                                            {element && element.name}
                                          </label>
                                          
                                          {/* Image Input */}
                                          <input 
                                            type="file" 
                                            id="image" 
                                            accept="image/*" 
                                            onChange={(e) => handleFile(e)} 
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            required
                                          />
                                          
                                          {/* Save Button */}
                                          <FaRegSave 
                                            title='Save' 
                                            onClick={(e) => saveInfo(element, e)} 
                                            className='text-[#F64401] absolute top-1/2 right-2 text-xl cursor-pointer' 
                                          />
                                        </div>
                                      </form>
                                    )
                                  }
                                  



                                }) : null
                            }
                          </div>
                          <div className=' flex-1 py-4 '>
                            {appSettingList && appSettingList.length > 0 ? (
                              appSettingList.map((element, index) => {
                                if (element && element.key && element.key === "HowToPlay") {
                                  return (<div className=''>
                                    <div className=' mb-1'> How To Play</div>
                                    <ReactQuill className='h-96  w-full   md:pb-14 pb-20' theme="snow" value={value} onChange={setValue} />
                                    <button className='bg-[#f64401] text-xl  text-white w-full rounded-md p-1' onClick={(e) => saveInfo(element, e)}> Save</button>
                                  </div>
                                  )
                                }
                              })
                            ) : null}



                          </div>

                        </div>
                      </div>



                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>





      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />


    </>
  );
};

export default AppSettingManagement;
