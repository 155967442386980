import {React,useState,useEffect} from "react";
import Modal from 'react-modal';
import { useSelector } from "react-redux";
import Select from 'react-select'

export default function CreateAppSettingModal(props) {
const [Options,setOptions] =useState([])

  let { createModal, handleCreateHideModal,inputChangeCreate2,selecteddata,addref,setselecteddata, inputChangeCreate, fieldsAppSetting, errorsAppSetting, createAppSettingSubmit, handleFile,allUserList } = props;
  console.log(allUserList)

  let slector = useSelector(state => state)
  let { users } = slector ? slector : {};
  let { fileData, loading } = users ? users : {};
  console.log(fileData)
  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id
      }));

      setOptions(options);
    }
  }, [allUserList])


  return (

    // <Modal
    //   isOpen={createModal}
    // >

      <div className={createModal?`fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster`:'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border  rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between bg-[#f64401] mb-3 rounded-lg px-2 py-3 text-white">
              <p className="text-2xl font-bold">Create Notification </p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleCreateHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <form autoComplete="off">

      
            <div className="mt-1 shadow-sm w-full">
                              <label class="block text-gray-700 text-base mb-2" for="name">User List :</label>
                              <Select
                              isMulti
                              name="colors"
                              value={selecteddata}
                              options={Options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={inputChangeCreate2}
                            />
                              {errorsAppSetting && errorsAppSetting["userId"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsAppSetting["userId"]}
                                </div>
                                : null}
                            </div>






              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="name">Title:</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["name"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="name" name="name" placeholder="Title"
                    value={fieldsAppSetting && fieldsAppSetting["name"] ? fieldsAppSetting["name"] : ""}

                    type="text" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["name"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["name"]}
                    </div>
                    : null}
                </div>
              </div>


              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="appsettingname">Message :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["key"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="key" name="key" placeholder="Message" value={fieldsAppSetting && fieldsAppSetting["key"] ? fieldsAppSetting["key"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["key"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["key"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="w-64 mx-auto mt-4 sm:w-72">
                <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#f64401] border border-[#f64401] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#f64401] focus:shadow-outline-yellow active:bg-[#f64401]" type="button" onClick={createAppSettingSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>


    // </Modal>



  );
}
