import { bankConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
export const bankActions = {
    getClientBankDetail,
    getAdminBankDetailList,
    addAdminBankDetail,
    updateAdminBankDetail,
    bankstatusUpdate,
    deleteAdminBankDetail,
    createNotification,
    calander,
    deleteNotification,

    getNotificationList,
};
function calander(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/calander'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    // dispatch(bankActions.getNotificationList(pagination));
    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.CHART_REQUEST, report } }
    function success(report) { return { type:  bankConstants.CHART_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.CHART_FAILURE, error } }
}
function deleteNotification(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteNotification'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(bankActions.getNotificationList(pagination));
    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.DELETE_NOTIFICATION_REQUEST, report } }
    function success(report) { return { type:  bankConstants.DELETE_NOTIFICATION_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.DELETE_NOTIFICATION_FAILURE, error } }
}
function getNotificationList(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNotificationList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.GETNOTIFICATION_LIST_REQUEST, report } }
    function success(report) { return { type:  bankConstants.GETNOTIFICATION_LIST_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.GETNOTIFICATION_LIST_FAILURE, error } }
}
function createNotification(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createNotification'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(bankActions.getNotificationList(pagination));
                    dispatch(alertActions.success("Notification sent successfully"));
                    // dispatch(getAdminBankDetailList(pagination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.CREATE_NOTIFICATION_ADMIN_REQUEST, report } }
    function success(report) { return { type:  bankConstants.CREATE_NOTIFICATION_ADMIN_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.CREATE_NOTIFICATION_ADMIN_FAILURE, error } }
}
function deleteAdminBankDetail(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteAdminBankDetail'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(alertActions.success("Deleted"))
                    dispatch(getAdminBankDetailList(pagination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_REQUEST, report } }
    function success(report) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_FAILURE, error } }
}

function bankstatusUpdate(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/bankstatusUpdate'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(alertActions.success("Status Changed"))
                    dispatch(getAdminBankDetailList(pagination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_REQUEST, report } }
    function success(report) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_FAILURE, error } }
}
function updateAdminBankDetail(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateAdminBankDetail'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(alertActions.success("Bank Details Updated"))
                    dispatch(getAdminBankDetailList(pagination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_REQUEST, report } }
    function success(report) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.UPDATE_BANK_DETAILS_ADMIN_FAILURE, error } }
}




function addAdminBankDetail(data,pagination) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/addAdminBankDetail'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(alertActions.success("Bank Added"))
                    dispatch(getAdminBankDetailList(pagination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.ADD_ADMIN_BANK_DETAIL_REQUEST, report } }
    function success(report) { return { type:  bankConstants.ADD_ADMIN_BANK_DETAIL_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.ADD_ADMIN_BANK_DETAIL_FAILURE, error } }
}











function getAdminBankDetailList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAdminBankDetailList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.GET_ADMIN_BANK_REQUEST, report } }
    function success(report) { return { type:  bankConstants.GET_ADMIN_BANK_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.GET_ADMIN_BANK_FAILURE, error } }
}










function getClientBankDetail(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getClientBankDetail'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type:  bankConstants.GET_BANK_DETAILS_BY_ADMIN_REQUEST, report } }
    function success(report) { return { type:  bankConstants.GET_BANK_DETAILS_BY_ADMIN_SUCCESS, report } }
    function failure(error) { return { type:  bankConstants.GET_BANK_DETAILS_BY_ADMIN_FAILURE, error } }
}