export const userConstants = {

    CLEAR_IMAGE: "CLEAR_IMAGE",
CHANGE_PASSWORD_ADMIN_REQUEST:"CHANGE_PASSWORD_ADMIN_REQUEST",
CHANGE_PASSWORD_ADMIN_SUCCESS:"CHANGE_PASSWORD_ADMIN_SUCCESS",
CHANGE_PASSWORD_ADMIN_FAILURE:"CHANGE_PASSWORD_ADMIN_FAILURE",


    GET_USER_OF_MASTER_REQUEST:"GET_USER_OF_MASTER_REQUEST",
    GET_USER_OF_MASTER_SUCCESS:"GET_USER_OF_MASTER_SUCCESS",
    GET_USER_OF_MASTER_FAILURE:"GET_USER_OF_MASTER_FAILURE",


    GET_TOTAL_COUNT_REQUEST:"GET_TOTAL_COUNT_REQUEST",
    GET_TOTAL_COUNT_SUCCESS:"GET_TOTAL_COUNT_SUCCESS",
    GET_TOTAL_COUNT_FAILURE:"GET_TOTAL_COUNT_FAILURE",

    GET_LOGIN_HISTORY_ID_REQUEST:"GET_LOGIN_HISTORY_ID_REQUEST",
    GET_LOGIN_HISTORY_ID_SUCCESS:"GET_LOGIN_HISTORY_ID_SUCCESS",
    GET_LOGIN_HISTORY_ID_FAILURE:"GET_LOGIN_HISTORY_ID_FAILURE",


    GET_TXN_LIST_USERID_REQUEST: "GET_TXN_LIST_USERID_REQUEST",
    GET_TXN_LIST_USERID_SUCCESS: "GET_TXN_LIST_USERID_SUCCESS",
    GET_TXN_LIST_USERID_FAILURE: "GET_TXN_LIST_USERID_FAILURE",


    GET_BANK_DETAILS_BY_ADMIN_REQUEST: "GET_BANK_DETAILS_BY_ADMIN_REQUEST",
    GET_BANK_DETAILS_BY_ADMIN_SUCCESS: "GET_BANK_DETAILS_BY_ADMIN_SUCCESS",
    GET_BANK_DETAILS_BY_ADMIN_FAILURE: "GET_BANK_DETAILS_BY_ADMIN_FAILURE",

    UPOLAD_IMAGE_REQUEST: "UPOLAD_IMAGE_REQUEST",
    UPOLAD_IMAGE_SUCCESS: "UPOLAD_IMAGE_SUCCESS",
    UPOLAD_IMAGE_FAILURE: "UPOLAD_IMAGE_FAILURE",

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_BET_LIST_USERID_REQUEST: "GET_BET_LIST_USERID_REQUEST",
    GET_BET_LIST_USERID_SUCCESS: "GET_BET_LIST_USERID_SUCCESS",
    GET_BET_LIST_USERID_FAILURE: "GET_BET_LIST_USERID_FAILURE",


    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    USER_CREATE_BY_ADMIN_REQUEST: 'USER_CREATE_BY_ADMIN_REQUEST',
    USER_CREATE_BY_ADMIN_SUCCESS: 'USER_CREATE_BY_ADMIN_SUCCESS',
    USER_CREATE_BY_ADMIN_FAILURE: 'USER_CREATE_BY_ADMIN_FAILURE',

    GET_ADMIN_PROFILE_REQUEST: 'GET_ADMIN_PROFILE_REQUEST',
    GET_ADMIN_PROFILE_SUCCESS: 'GET_ADMIN_PROFILE_SUCCESS',
    GET_ADMIN_PROFILE_FAILURE: 'GET_ADMIN_PROFILE_FAILURE',

    GET_USER_BY_USERID_REQUEST: 'GET_USER_BY_USERID_REQUEST',
    GET_USER_BY_USERID_SUCCESS: 'GET_USER_BY_USERID_SUCCESS',
    GET_USER_BY_USERID_FAILURE: 'GET_USER_BY_USERID_FAILURE',

    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    UPDATE_USER_STATUS_REQUEST: 'UPDATE_USER_STATUS_REQUEST',
    UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
    UPDATE_USER_STATUS_FAILURE: 'UPDATE_USER_STATUS_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

};
