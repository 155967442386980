import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./SliderManagement.json";
import { sliderActions, userActions } from "../../_actions";
import CreateSliderModal from "./components/CreateSliderModal/CreateSliderModal";
import UpdateSliderModal from "./components/UpdateSliderModal/UpdateSliderModal";
import ViewSliderModal from "./components/ViewSliderModal/ViewSliderModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";

import { MdOutlineDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

const SliderManagement = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsSlider, setFieldsSlider] = useState({});
  const [errorsSlider, setErrorsSlider] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const { sliderList, sliderTotal, loading } = useSelector(
    (state) => state.sliders
  );

  useEffect(() => {
    let sliderReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(sliderActions.getSliderList(sliderReq));
  }, []);

  let addref = useRef(null);
  const handleCreateOpenModal = () => {
    if (addref?.current?.value) {
      addref.current.value = "";
    }
    setCreateModal(true);
    dispatch(userActions.clearImage());
  };

  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsSlider({});
    setErrorsSlider({});
    dispatch(userActions.clearImage());
  };

  const handleUpdateOpenModal = (data) => {
    setFieldsUpdate(data);
    setUpdateModal(true);
  };

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  };

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsSlider((prevState) => ({ ...prevState, [name]: value }));
    setErrorsSlider((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateSliderSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    console.log(fileData);
    e.preventDefault();
    if (handleValidationUpdateSlider()) {
      let sliderPageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, _id, image } = fieldsUpdate;
      dispatch(
        sliderActions.updateSlider(
          { name: name && name.trim(), id: _id, image: fileData || image },
          setUpdateModal,
          sliderPageRefresh
        )
      );
    }
  };

  const handleValidationUpdateSlider = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const createSliderSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData } = users ? users : {};
    e.preventDefault();
    if (handleValidationCreateSlider()) {
      let sliderPageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const { name } = fieldsSlider;
      dispatch(
        sliderActions.createSlider(
          {
            name: name && name.trim(),
            image: fileData,
          },
          setCreateModal,
          sliderPageRefresh
        )
      );
      setFieldsSlider({});
      setErrorsSlider({});
      dispatch(userActions.clearImage());
    }
  };

  const handleValidationCreateSlider = () => {
    let { users } = selector ? selector : {};
    let { fileData } = users ? users : {};
    let formIsValid = true;
    let errors = {};

    if (!fieldsSlider.name || fieldsSlider.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fileData || fileData === "") {
      formIsValid = false;
      errors.img = "Image is Required";
    }

    setErrorsSlider(errors);
    return formIsValid;
  };

  const disableSlider = (data) => {
    let sliderIdReq = {
      id: data._id,
    };
    let sliderPageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    confirmAlert({
      title: data?.isDisable
        ? "Confirm to Enable Slider?"
        : "Confirm to Disable Slider?",
      message: data?.isDisable
        ? `Are you sure you want to Enable ${data.name}?`
        : `Are you sure you want to Disable ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              sliderActions.updateSliderStatus(sliderIdReq, sliderPageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteSlider = (data) => {
    let sliderIdReq = {
      id: data._id,
    };

    let sliderPageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    confirmAlert({
      title: "Confirm to Delete?",
      message: `Are you sure you want  to delete ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              sliderActions.deleteSlider(sliderIdReq, sliderPageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFile = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
      setErrorsSlider((prevState) => ({ ...prevState, img: "" }));
    } else {
      console.log("No File To Upload!");
    }
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };

    dispatch(sliderActions.getSliderList(pageReq));
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between  ">
                        <div>
                          <p className="text-2xl font-bold">Slider</p>
                        </div>
                        <div>
                          <span className="relative ">
                            <button
                              className="px-4 py-2 font-medium tracking-wider text-white bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                              onClick={() => handleCreateOpenModal()}
                            >
                              Add
                            </button>
                          </span>{" "}
                        </div>
                      </sectiion>

                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg hidden md:block  border">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-sm text-gray-800 uppercase bg-gray-50 ">
                              <tr>
                                <th scope="col" className="px-6 py-3">
                                  S.No.
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Active
                                </th>
                                <th scope="col" className="px-6 py-3 ">
                                  action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sliderList && sliderList.length > 0
                                ? sliderList.map((element, index) => (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className={
                                          index % 2 === 0
                                            ? "bg-white border-b "
                                            : "bg-gray-100 "
                                        }
                                      >
                                        <th
                                          scope="row"
                                          className="px-6 py-4   whitespace-nowrap "
                                        >
                                          {offset + index + 1}
                                        </th>
                                        <th
                                          scope="row"
                                          className="px-6 py-4   whitespace-nowrap capitalize"
                                        >
                                          {element && element.name
                                            ? element.name
                                            : "-"}
                                        </th>
                                        <td className="px-6 py-4">
                                          <img
                                            src={
                                              element && element.image
                                                ? element.image
                                                : "-"
                                            }
                                            alt="slider_img"
                                            className="rounded-md w-20 h-20"
                                          />
                                        </td>
                                        <td className="px-6 py-4">
                                          <button
                                            className={
                                              element.isDisable === false
                                                ? "px-4 py-1 border border-green-700  bg-green-700 text-white rounded-2xl"
                                                : "px-4 py-1 border border-red-700 text-white bg-red-700 rounded-2xl"
                                            }
                                            onClick={() =>
                                              disableSlider(element)
                                            }
                                          >
                                            {element &&
                                            element.isDisable === false
                                              ? "Yes"
                                              : "No"}
                                          </button>
                                        </td>
                                        <td className="px-6 py-4 text-right  place-items-center gap-1">
                                          <div className="flex justify-start gap-2 place-items-center">
                                          <span className="relative ">
                                            <FiEdit
                                              onClick={() =>
                                                handleUpdateOpenModal(element)
                                              }
                                              title="Edit"
                                              className=" text-2xl text-blue-600 cursor-pointer"
                                            />
                                          </span>
                                          <span className="relative ">
                                            <MdOutlineDelete
                                              onClick={() =>
                                                deleteSlider(element)
                                              }
                                              title="Delete"
                                              className=" text-2xl text-red-600 cursor-pointer"
                                            />
                                          </span>
                                          </div>
                                         
                                         
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                        {sliderList && sliderList.length > 0 ? (
                          sliderList?.map((element, index) => (
                            <div className="block md:hidden relative overflow-x-auto shadow-md sm:rounded-lg my-2 p-4 capitalize border rounded-md">
                              <div className="grid grid-cols-2 gap-3 py-2 ">
                                <div className="]">
                                  <p className="">S No</p>
                                </div>
                                <div className="]">
                                  <p className="text-start">
                                    {" "}
                                    {offset + index + 1}
                                  </p>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-3">
                                <div>
                                  <p>Name</p>
                                </div>
                                <div>
                                  <p>
                                    {" "}
                                    {element && element.name
                                      ? element.name
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-3 py-2">
                                <div>
                                  <p>Image</p>
                                </div>
                                <div>
                                  <p>
                                    {" "}
                                    <img
                                      src={
                                        element && element.image
                                          ? element.image
                                          : "-"
                                      }
                                      alt=" "
                                      className="rounded-md w-14  h-14"
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-3 py-2">
                                <div>
                                  <p>Active</p>
                                </div>
                                <div>
                                  <p>
                                    {" "}
                                    <button
                                      className={
                                        element.isDisable === false
                                          ? "px-4 py-2  border border-green-700  bg-green-700 text-white rounded-2xl"
                                          : "px-4 py-2 border border-red-700 text-white bg-red-700 rounded-2xl"
                                      }
                                      onClick={() => disableSlider(element)}
                                    >
                                      {element && element.isDisable === false
                                        ? "Yes"
                                        : "No"}
                                    </button>
                                  </p>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-3">
                                <div>
                                  <p>Action</p>
                                </div>
                                <div>
                                  <p className="flex py-2 px-3">
                                    <span className="relative ">
                                      <FiEdit
                                        onClick={() =>
                                          handleUpdateOpenModal(element)
                                        }
                                        title="Edit"
                                        className=" text-2xl text-blue-600 cursor-pointer"
                                      />
                                    </span>
                                    <span className="relative ">
                                      <MdOutlineDelete
                                        onClick={() => deleteSlider(element)}
                                        title="Delete"
                                        className=" text-2xl text-red-600 cursor-pointer"
                                      />
                                    </span>{" "}
                                    &nbsp;
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>

                  {isMobile ? (
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {sliderTotal && sliderTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={sliderTotal / size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  ) : (
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {sliderTotal && sliderTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={sliderTotal / size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  )}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateSliderModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsSlider={fieldsSlider}
        errorsSlider={errorsSlider}
        createSliderSubmit={createSliderSubmit}
        addref={addref}
      />

      <UpdateSliderModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateSliderSubmit={updateSliderSubmit}
      />

      <ViewSliderModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        // inputChange={inputChange}
        rowData={rowData}
        // errorsUpdate={errorsUpdate}
        // updateSliderSubmit={updateSliderSubmit}
      />
    </>
  );
};

export default SliderManagement;
