import React from "react";
import Modal from 'react-modal';
import { useSelector } from "react-redux";

export default function UpdateSliderModal(props) {

  let { updateModal, handleUpdateHideModal, inputChange, fieldsUpdate, errorsUpdate, updateSliderSubmit, handleFile } = props;
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {};
  let { fileData, loading } = users ? users : {};

  return (

    // <Modal
    //   isOpen={updateModal}
    // >

    <div className={updateModal ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster` : 'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex items-center justify-between px-1 py-2 bg-[#7D1970] text-white rounded-lg">
            <p className="text-2xl font-bold">Update Slider</p>
            <div className="z-50 cursor-pointer modal-close">
              <svg onClick={() => handleUpdateHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          {loading
  &&
  <div className=" absolute top-1/2 right-1/2" role="status">
  <svg
    aria-hidden="true"
    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
  <span className="sr-only">Loading...</span>
</div>

}
          <form autoComplete="off">
            <div className="{otpSent?'disableArea':''}">
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" >Name :</label>
                <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["name"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="name" name="name" placeholder="SliderName"
                  // value={fieldsUpdate.name}
                  value={fieldsUpdate && fieldsUpdate["name"] ? fieldsUpdate["name"] : ""}

                  type="text" onChange={inputChange} />
                {errorsUpdate && errorsUpdate["name"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUpdate["name"]}
                  </div>
                  : null}
              </div>
            </div>

            {/* {
                filesDetails ?
                  <>
                    <div className="w-full mt-1 mr-2 ">
                      <img src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : "NA"}
                        alt=" " height="20%" width="20%" />
                    </div>
                  </> :
                  null
              }

              <div className="flex flex-wrap w-full mt-2">
                <label htmlFor="image">Upload Image</label>
                <input
                  // style={{ display: 'none' }}
                  id="image"
                  name="image"
                  type="file"
                  onChange={handleFile}
                />
              </div> */}


            <div className="w-full mt-1 mr-2 ">

              {fileData ?
                <img src={fileData && fileData ? fileData : "NA"}
                  alt=" " height="20%" width="20%" /> :
                <img src={fieldsUpdate && fieldsUpdate.image ? fieldsUpdate.image : "NA"}
                  alt=" " height="20%" width="20%" />


              }

            </div>



            <div className="flex flex-wrap w-full mt-2">
              <label htmlFor="image">Upload Image</label>
              <input
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />
            </div>


            <div className="w-64 mx-auto mt-4 sm:w-72">
              <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bg-[#7D1970]" type="button" onClick={updateSliderSubmit}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>


    // </Modal>



  );
}
