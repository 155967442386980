export const ticketConstants = {

    CREATE_TICKET_REQUEST: 'CREATE_TICKET_REQUEST',
    CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
    CREATE_TICKET_FAILURE: 'CREATE_TICKET_FAILURE',

    GET_ALL_TICKET_REQUEST: 'GET_ALL_TICKET_REQUEST',
    GET_ALL_TICKET_SUCCESS: 'GET_ALL_TICKET_SUCCESS',
    GET_ALL_TICKET_FAILURE: 'GET_ALL_TICKET_FAILURE',

    GET_TICKET_LIST_REQUEST: 'GET_TICKET_LIST_REQUEST',
    GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
    GET_TICKET_LIST_FAILURE: 'GET_TICKET_LIST_FAILURE',

    UPDATE_TICKET_REQUEST: 'UPDATE_TICKET_REQUEST',
    UPDATE_TICKET_SUCCESS: 'UPDATE_TICKET_SUCCESS',
    UPDATE_TICKET_FAILURE: 'UPDATE_TICKET_FAILURE',

    UPDATE_TICKET_STATUS_REQUEST: 'UPDATE_TICKET_STATUS_REQUEST',
    UPDATE_TICKET_STATUS_SUCCESS: 'UPDATE_TICKET_STATUS_SUCCESS',
    UPDATE_TICKET_STATUS_FAILURE: 'UPDATE_TICKET_STATUS_FAILURE',

    DELETE_TICKET_REQUEST: 'DELETE_TICKET_REQUEST',
    DELETE_TICKET_SUCCESS: 'DELETE_TICKET_SUCCESS',
    DELETE_TICKET_FAILURE: 'DELETE_TICKET_FAILURE',

};
