import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import DashboardJSON from './UserManagement.json'
import { userActions } from '../../_actions';
import CreateUserModal from "./components/CreateUserModal/CreateUserModal";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { FaEye } from "react-icons/fa";
import moment from 'moment'
import Loader from '../../components/Loader/Loader';
import { IoIosArrowBack } from "react-icons/io";
// import Table from '../../components/Atoms/Table/Table';


const SubAdminUser = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let [rowData, setRowData] = useState(null);
  let [userOpenModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUser, setfieldsUser] = useState({});
  const [FieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUser, setErrorsUpdate] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  let selector = useSelector(state => state)

  const { userList, userTotal } = useSelector(state => state.users);
  let addRef = useRef(null)
  const params = useParams()
  console.log(params)

  useEffect(() => {
    let myBetReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size,
      roleId: 100,
    }

    let obj = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size,
      masterId: params?.masterId
    }


    dispatch(userActions.getUserOfMaster(obj));

  }, [params]);


  const handleOpenModal = () => {
    setCreateModal(true);
  }

  const handleHideModal = (data) => {
    dispatch(userActions.clearImage());
    if (addRef.current) {
      addRef.current.value = "";
    }
    setCreateModal(false);
    setErrorsUpdate({});
    setfieldsUser({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setfieldsUser(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const createUserSubmit = (e) => {
    let { users } = selector ? selector : {}
    let { fileData, loading } = users ? users : {}
    e.preventDefault();
    if (handleValidationUser()) {

      let userPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": size,
        roleId: 100,
      }

      const { userName, mobNo, email, password, confirmPassword } = fieldsUser;

      let obj = {
        userName: userName && userName.trim(),
        mobNo: mobNo && mobNo.trim(),
        email: email && email.trim(),
        password: password.trim(),
        roleId: 100,
        confirmPassword: confirmPassword && confirmPassword.trim(),
        image: fileData ? fileData : ' ',
      }

      dispatch(userActions.registerUserByAdmin(obj, userPageRefresh));
      handleHideModal()

    }
  };

  const handleValidationUser = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = DashboardJSON.ErrorMsg.userName;
    }
    if (fieldsUser.userName.includes(" ")) {
      formIsValid = false;
      errors.userName = "Space is not allowed";
    }

    if (!fieldsUser.userName || fieldsUser.userName.length < 5) {
      formIsValid = false;
      errors.userName = "User name must be at least 5 characters";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = DashboardJSON.ErrorMsg.mobNo;
    } else if (!/^\d{10}$/.test(fieldsUser.mobNo.trim())) {
      errors.mobNo = "Mobile number must be exactly 10 digits.";
    }

    if (!fieldsUser.email || fieldsUser.email.trim() === "") {
      formIsValid = false;
      errors.email = DashboardJSON.ErrorMsg.email;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailPattern.test(fieldsUser.email.trim())) {

        formIsValid = false;
        errors.email = "Please enter a valid email ";
      }
    }


    if (!fieldsUser.password || fieldsUser.password.trim() === "") {
      formIsValid = false;
      errors.password = DashboardJSON.ErrorMsg.password;
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/.test(fieldsUser.password)
    ) {
      formIsValid = false;
      errors.password =
        'Password must be 8 to 15 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
    }


    if (!fieldsUser.confirmPassword || fieldsUser.confirmPassword.trim() === "") {
      formIsValid = false;
      errors.confirmPassword = DashboardJSON.ErrorMsg.confirmPassword;
    }
    if (fieldsUser.confirmPassword !== fieldsUser.password) {
      formIsValid = false;
      errors.confirmPassword = "Password and confirm password must be same";
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const disableUser = (data) => {
    console.log(data)
    let userIdReq = {
      "userId": data._id,
    }
    let userPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size,
      roleId: 100,
    }
    confirmAlert({

      title: data?.isDisable ? 'Confirm to Enable User?' : 'Confirm to Disable User?',
      message: data?.isDisable ? `Are you sure you want to Enable ${data.userName}?` : `Are you sure you want to Disable ${data.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.updateUserStatus(userIdReq, userPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const deleteUser = (data) => {

    // console.log('datadatadatadatadatadatadatadatadata', data);

    let userIdReq = {
      "userId": data._id,
    }

    let userPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size,
      roleId: 100,
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure you want to delete ${data.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.deleteUser(userIdReq, userPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }




  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      "pageNo": data.selected + 1,
      "keyWord": "",
      "size": size,
      masterId: params?.masterId
    }


    let obj = {

    }
    dispatch(userActions.getUserOfMaster(pageReq));
  }

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    let pageReq = {
      "keyWord": value,
      "pageNo": 1,
      "size": size,
      masterId: params?.masterId
    }
    dispatch(userActions.getUserOfMaster(pageReq));
  };

  const handleInputSize = (e) => {

    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    setSize(e.target.value)
    setOffset(0)
    let pageReq = {

      "keyWord": "",
      "pageNo": parseInt(pageNo),
      "size": parseInt(value),
      masterId: params?.masterId

    }





    



    dispatch(userActions.getUserOfMaster(pageReq));
  };



  const handleFile = async (e) => {
    dispatch(userActions.uploadImage(e))
  };




  let { users } = selector ? selector : {}
  let { loading, adminUsersList, adminUsersTotal } = users ? users : {}
  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div>

            <IoIosArrowBack onClick={()=>navigate(-1)} className='bg-[#F8855C] text-white text-2xl cursor-pointer  rounded-full' />
                    </div>
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div>
                          <p className='text-2xl font-bold'>{params?.mastername} (Users)</p>
                        </div>
                      </sectiion>

                      <div className='bg-white rounded-xl'>
                        <div className='flex justify-between items-center px-3 py-2 border-b'>
                          <div className='flex gap-2 justify-between w-full items-end px-3 py-2 border-b'>
                            <div>
                              <label class="block text-gray-700 text-base mb-2 whitespace-nowrap" for="name">Show Data</label>
                              <select className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="size" name="size" placeholder="name"
                                value={fieldsGame && fieldsGame["size"] ? fieldsGame["size"] : ""} type="text"
                                onChange={(e) => handleInputSize(e)}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                            </div>
                            <div className="w-64 mt-1  shadow-sm">

                              <div className="w-64 my-2  flex justify-end shadow-sm">
                                {/* <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                  onClick={() => handleOpenModal()}
                                >Add User</button> */}
                              </div>

                              {/* <label class="block text-gray-700 text-base text-right mb-2" for="gamename">Search</label> */}
                              <input placeholder='Search' className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="search" name="search" value={fieldsGame && fieldsGame["search"] ? fieldsGame["search"] : ""} type="text"
                                onChange={handleInputSearch}
                              />
                              {errorsGame && errorsGame["search"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["search"]}
                                </div>
                                : null}
                            </div>

                          </div>



                        </div>
                        <div className="relative overflow-x-auto p-5">

                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-sm text-gray-700 capitalize  bg-gray-50 ">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">user Name</th>
                                <th className="px-6 py-3">Email</th>
                                <th className="px-6 py-3">Balance</th>
                                <th className="px-6 py-3 text-right">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {adminUsersList && adminUsersList.length > 0 ? adminUsersList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white border-b border-gray-300">
                                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                      {offset + index + 1}
                                    </th>
                                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                      {element && element.userName ? element.userName : "-"}
                                    </th>
                                    <td className="px-6 py-4">
                                      {element && element.email ? element.email : "-"}
                                    </td>
                                    <td className="px-6 py-4">
                                      {element && element.walletBalance ? element.walletBalance : "0"}
                                    </td>
                                    {/* <td className="px-6 py-4 whitespace-nowrap">
                                      {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY") : "-"}
                                    </td> */}
                                    {/* <td className="px-6 py-4">
                                      {element && element.wallet ? element.wallet : "0"}
                                    </td> */}
                                    {/* <td className="px-6 py-4">
                                      <button className={element.isDisable === false ?'px-4 py-2 border border-green-700  bg-green-700 text-white rounded-2xl':'px-4 py-2 border border-red-700 text-white bg-red-700 rounded-2xl'} onClick={() => disableUser(element)}>
                                        {element && element.isDisable === false ? "Yes" : "No"}
                                      </button>
                                    </td> */}
                                    {/* <td className="px-6 py-4">
                                      <button className="px-4 py-2 border border-[#7D1970] rounded-2xl"  onClick={() => handleEditUser("/app/user/", element)}
                                      >
                                        <FaEye className='text-[#7D1970]' />
                                      </button>
                                    </td> */}
                                    <td className="px-6 py-4 text-right flex justify-end items-end">
                                      <span></span>
                                      {element?.isDisable ? <span className='text-red-500'>Deactive</span> : <span className=' text-green-800'>Active</span>}
                                    </td>

                                  </tr>
                                </React.Fragment>
                              )) : null
                              }
                            </tbody>
                          </table>

                        </div>
                        {
                          isMobile ?
                            <nav className="relative z-0 flex justify-end mt-5 w-76">
                              {
                                adminUsersTotal && adminUsersTotal > size ?
                                  <ReactPaginate
                                    previousLabel={'Prev'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={adminUsersTotal / size}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-cls'}
                                    activeClassName={'active'}
                                  />
                                  : null}
                            </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                              {
                                adminUsersTotal && adminUsersTotal > size ?
                                  <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={adminUsersTotal / size}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-cls'}
                                    activeClassName={'active'}
                                  />
                                  : null}
                            </nav>
                        }
                      </div>


                    </div>
                  </div>



                </main>
              </div>
            </div>
          </div>
        </div>
      </div>


      <CreateUserModal
        userOpenModal={userOpenModal}
        handleHideModal={handleHideModal}
        inputChange={inputChange}
        fieldsUser={fieldsUser}
        errorsUser={errorsUser}
        createUserSubmit={createUserSubmit}
        handleFile={handleFile}
        addRef={addRef}
      />

      <ViewUserModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />


    </>
  );
};

export default SubAdminUser;
