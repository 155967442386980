export const marketConstants = {

    CREATE_MARKET_REQUEST: 'CREATE_MARKET_REQUEST',
    CREATE_MARKET_SUCCESS: 'CREATE_MARKET_SUCCESS',
    CREATE_MARKET_FAILURE: 'CREATE_MARKET_FAILURE',

    GET_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST: 'GET_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST',
    GET_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS: 'GET_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS',
    GET_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE: 'GET_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE',

    GET_ALL_MARKET_REQUEST: 'GET_ALL_MARKET_REQUEST',
    GET_ALL_MARKET_SUCCESS: 'GET_ALL_MARKET_SUCCESS',
    GET_ALL_MARKET_FAILURE: 'GET_ALL_MARKET_FAILURE',

    GET_MARKET_LIST_REQUEST: 'GET_MARKET_LIST_REQUEST',
    GET_MARKET_LIST_SUCCESS: 'GET_MARKET_LIST_SUCCESS',
    GET_MARKET_LIST_FAILURE: 'GET_MARKET_LIST_FAILURE',

    UPDATE_MARKET_REQUEST: 'UPDATE_MARKET_REQUEST',
    UPDATE_MARKET_SUCCESS: 'UPDATE_MARKET_SUCCESS',
    UPDATE_MARKET_FAILURE: 'UPDATE_MARKET_FAILURE',

    UPDATE_MARKET_STATUS_REQUEST: 'UPDATE_MARKET_STATUS_REQUEST',
    UPDATE_MARKET_STATUS_SUCCESS: 'UPDATE_MARKET_STATUS_SUCCESS',
    UPDATE_MARKET_STATUS_FAILURE: 'UPDATE_MARKET_STATUS_FAILURE',

    DELETE_MARKET_REQUEST: 'DELETE_MARKET_REQUEST',
    DELETE_MARKET_SUCCESS: 'DELETE_MARKET_SUCCESS',
    DELETE_MARKET_FAILURE: 'DELETE_MARKET_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    FILE_UPLOAD_CLEAR_SUCCESS:'FILE_UPLOAD_CLEAR_SUCCESS'

};
