import { appSettingConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPublicAPI, logoutFunction, headerForPrivateAPI } from '../_helpers';
export const appSettingActions = {
    createAppSetting,
    getAppSettingList,
    getAllAppSetting,
    updateAppSetting,
    updateAppSettingStatus,
    deleteAppSetting,
};

function createAppSetting(data, setCreateModal, setFieldsAppSetting, appsettingPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                appSetting => {
                    dispatch(success(appSetting));
                    dispatch(getAppSettingList(appsettingPageRefresh));
                    setCreateModal(false);
                    setFieldsAppSetting({});
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(appSetting) { return { type: appSettingConstants.CREATE_APP_SETTING_REQUEST, appSetting } }
    function success(appSetting) { return { type: appSettingConstants.CREATE_APP_SETTING_SUCCESS, appSetting } }
    function failure(error) { return { type: appSettingConstants.CREATE_APP_SETTING_FAILURE, error } }
}
function getAppSettingList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAppSettingList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                appSetting => {
                    dispatch(success(appSetting));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(appSetting) { return { type: appSettingConstants.GET_APP_SETTING_LIST_REQUEST, appSetting } }
    function success(appSetting) { return { type: appSettingConstants.GET_APP_SETTING_LIST_SUCCESS, appSetting } }
    function failure(error) { return { type: appSettingConstants.GET_APP_SETTING_LIST_FAILURE, error } }
}

function updateAppSetting(data, appsettingPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                appSetting => {
                    dispatch(success(appSetting));
                    dispatch(getAppSettingList(appsettingPageRefresh));
                    dispatch(alertActions.success("Updated"));
                    // setUpdateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(appSetting) { return { type: appSettingConstants.UPDATE_APP_SETTING_REQUEST, appSetting } }
    function success(appSetting) { return { type: appSettingConstants.UPDATE_APP_SETTING_SUCCESS, appSetting } }
    function failure(error) { return { type: appSettingConstants.UPDATE_APP_SETTING_FAILURE, error } }
}

function updateAppSettingStatus(data, appsettingPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateAppSettingStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                appSetting => {
                    dispatch(success(appSetting));
                    dispatch(getAppSettingList(appsettingPageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(appSetting) { return { type: appSettingConstants.UPDATE_APP_SETTING_STATUS_REQUEST, appSetting } }
    function success(appSetting) { return { type: appSettingConstants.UPDATE_APP_SETTING_STATUS_SUCCESS, appSetting } }
    function failure(error) { return { type: appSettingConstants.UPDATE_APP_SETTING_STATUS_FAILURE, error } }
}

function deleteAppSetting(data, appsettingPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                appSetting => {
                    dispatch(success(appSetting));
                    dispatch(getAppSettingList(appsettingPageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(appSetting) { return { type: appSettingConstants.DELETE_APP_SETTING_REQUEST, appSetting } }
    function success(appSetting) { return { type: appSettingConstants.DELETE_APP_SETTING_SUCCESS, appSetting } }
    function failure(error) { return { type: appSettingConstants.DELETE_APP_SETTING_FAILURE, error } }
}

function getAllAppSetting(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllAppSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                appSetting => {
                    dispatch(success(appSetting));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(appSetting) { return { type: appSettingConstants.GET_ALL_APP_SETTING_REQUEST, appSetting } }
    function success(appSetting) { return { type: appSettingConstants.GET_ALL_APP_SETTING_SUCCESS, appSetting } }
    function failure(error) { return { type: appSettingConstants.GET_ALL_APP_SETTING_FAILURE, error } }
}


