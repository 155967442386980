import React, { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import { logoutFunction } from "../../_helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({HeaderJSON}) {

  const handelLogout = () => {
    logoutFunction()
  }
  return (
    <Fragment>
      {/* <div className="bg-[#f8855c] relative z-10 flex-shrink-0 flex h-16 border-b border-black border-opacity-10"> */}
      <div className="bg-[#F64401] relative z-10 flex-shrink-0 flex h-16 border-b border-black border-opacity-10">
        <div className="flex-1 px-4 flex justify-between">
          <div>
          </div>
          <div className="flex justify-center items-center space-x-4">
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <div className="font-semibold text-white">{HeaderJSON["Admin-name"]}</div>
            <Menu as="div" className="relative inline-block text-left">
              {({ open }) => (
                <>
                  <div className="ml-6 relative">
                    <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
                      <img src={`/${HeaderJSON["Admin-image"]}`} alt="" className="rounded-full relative flex justify-center items-center mr-4" width={"50px"} />
                    </Menu.Button>
                  </div>
                  <Transition show={open} as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span onClick={handelLogout} className={classNames(active ? 'bg-shine text-gray-900' : 'text-gray-900', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium hover:bg-slate-600 hover:text-white')}>Logout</span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
