import {
  marketConstants
} from '../_constants';

export default function markets(state = {}, action) {
  switch (action.type) {

    case marketConstants.CREATE_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.CREATE_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case marketConstants.CREATE_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.GET_ALL_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.GET_ALL_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allMarket: action.market.data,
        // marketTotal: action.market.data.total,
        loading: false,
      };
    case marketConstants.GET_ALL_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.GET_MARKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.GET_MARKET_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        marketList: action.market.data.list,
        marketTotal: action.market.data.total,
        loading: false,
      };
    case marketConstants.GET_MARKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        marketDataIdWise: action.market.data,
        loading: false,
      };
    case marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.UPDATE_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.UPDATE_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case marketConstants.UPDATE_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.UPDATE_MARKET_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.UPDATE_MARKET_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case marketConstants.UPDATE_MARKET_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.DELETE_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.DELETE_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case marketConstants.DELETE_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.FILE_UPLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadSuccess: true,
        // filesDetails: action.uploadImage.filesDetails
      };
    case marketConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case marketConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };


    default:
      return state
  }
}