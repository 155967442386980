export const sliderConstants = {

    CREATE_SLIDER_REQUEST: 'CREATE_SLIDER_REQUEST',
    CREATE_SLIDER_SUCCESS: 'CREATE_SLIDER_SUCCESS',
    CREATE_SLIDER_FAILURE: 'CREATE_SLIDER_FAILURE',

    GET_ALL_SLIDER_REQUEST: 'GET_ALL_SLIDER_REQUEST',
    GET_ALL_SLIDER_SUCCESS: 'GET_ALL_SLIDER_SUCCESS',
    GET_ALL_SLIDER_FAILURE: 'GET_ALL_SLIDER_FAILURE',

    GET_SLIDER_LIST_REQUEST: 'GET_SLIDER_LIST_REQUEST',
    GET_SLIDER_LIST_SUCCESS: 'GET_SLIDER_LIST_SUCCESS',
    GET_SLIDER_LIST_FAILURE: 'GET_SLIDER_LIST_FAILURE',

    UPDATE_SLIDER_REQUEST: 'UPDATE_SLIDER_REQUEST',
    UPDATE_SLIDER_SUCCESS: 'UPDATE_SLIDER_SUCCESS',
    UPDATE_SLIDER_FAILURE: 'UPDATE_SLIDER_FAILURE',

    UPDATE_SLIDER_STATUS_REQUEST: 'UPDATE_SLIDER_STATUS_REQUEST',
    UPDATE_SLIDER_STATUS_SUCCESS: 'UPDATE_SLIDER_STATUS_SUCCESS',
    UPDATE_SLIDER_STATUS_FAILURE: 'UPDATE_SLIDER_STATUS_FAILURE',

    DELETE_SLIDER_REQUEST: 'DELETE_SLIDER_REQUEST',
    DELETE_SLIDER_SUCCESS: 'DELETE_SLIDER_SUCCESS',
    DELETE_SLIDER_FAILURE: 'DELETE_SLIDER_FAILURE',

};
