import {
  userConstants
} from '../_constants';

export default function users(state = {}, action) {
  switch (action.type) {
    
  
    case userConstants.CHANGE_PASSWORD_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CHANGE_PASSWORD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.CHANGE_PASSWORD_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_USER_OF_MASTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_OF_MASTER_SUCCESS:
      return {
        ...state,
        adminUsersList:action?.user?.data?.results,
        adminUsersTotal:action?.user?.data?.total,
        loading: false,
      };
    case userConstants.GET_USER_OF_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_TOTAL_COUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        totalCounts:action?.user,
        loading: false,
      };
    case userConstants.GET_TOTAL_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.CLEAR_IMAGE:
      return {
        ...state,
        fileData: null,
      };





    case userConstants.GET_LOGIN_HISTORY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LOGIN_HISTORY_ID_SUCCESS:
      return {
        ...state,
        LoginSinglUSerList: action?.user?.data?.list,
        LoginSinglUSertotal: action?.user?.data?.total,
        loading: false,
      };
    case userConstants.GET_LOGIN_HISTORY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_TXN_LIST_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TXN_LIST_USERID_SUCCESS:
      return {
        ...state,
        tnxList: action?.user?.data?.list,
        tnxTotal: action?.user?.data?.total,
        loading: false,
      };
    case userConstants.GET_TXN_LIST_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_BET_LIST_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_BET_LIST_USERID_SUCCESS:
      return {
        ...state,
        betsListtotal: action?.user?.data?.total,
        betsListdata: action?.user?.data?.list,
        loading: false,
      };
    case userConstants.GET_BET_LIST_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case userConstants.UPOLAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPOLAD_IMAGE_SUCCESS:
      return {
        ...state,
        fileData: action?.response?.data?.imageURL,
        loading: false,
      };
    case userConstants.UPOLAD_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_CREATE_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_CREATE_BY_ADMIN_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
      };
    case userConstants.USER_CREATE_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_BY_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_BY_USERID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        userDetailsById: action?.user?.data,
        loading: false,
      };
    case userConstants.GET_USER_BY_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        userList: action.user.data.list,
        userTotal: action.user.data.total,
        loading: false,
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        adminProfileDetails: action.user.data,
        loading: false,
      };
    case userConstants.GET_ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}