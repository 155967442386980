import React from "react";
import Modal from 'react-modal';

export default function ViewMarketModal(props) {

  let { viewModal, handleViewHideModal, rowData } = props;




  return (

    // <Modal
    //   isOpen={viewModal}
    // >

    <div className={viewModal ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster` : "hidden  "} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50  md:w-[50vw] w-[90vw] mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container ">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex bg-[#F64401] text-white items-center p-1 justify-between  rounded-md">
            <p className="text-2xl font-bold">View Details</p>
            <div className="z-50 cursor-pointer modal-close">
              <svg onClick={() => handleViewHideModal()} className=" fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete="off" >
            <div className="{otpSent?'disableArea':''}">
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="name">Name :</label>
                <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                  id="name" name="name" placeholder="name"
                  value={rowData && rowData["name"] ? rowData["name"] : ""}
                  type="text" disabled
                />
              </div>
            </div>

            <div className="{otpSent?'disableArea':''}">
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="name">Short Name :</label>
                <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                  id="shortName" name="shortName" placeholder="shortName"
                  value={rowData && rowData["shortName"] ? rowData["shortName"] : ""}
                  type="text" disabled
                />
              </div>
            </div>

            <div className="{otpSent?'disableArea':''}  max-h-[30vh] overflow-scroll p-2">
              <div className="relative mt-1  shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="name">Number List :</label>
                <div className=" flex gap-3 ">

<div className="flex gap-3 flex-wrap">
  {rowData &&
    rowData['numList'] &&
    rowData['numList'].length > 0 &&
    rowData['numList'].map((e, index) =>
      Array.isArray(e) ? (
        <div key={index} className="p-2 px-3 rounded-md">
          {/* Render as a table */}
          <table>
            <tbody>

              {e.map((elem, index2) => (
                <tr key={index2} className={index2 % 2 === 0 ? 'bg-gray-300' : 'bg-gray-100'}>
                  <td className=" p-2 rounded-md">{elem}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (

        <div key={index} className="border-2 p-2 px-3 rounded-md">
          {e}
        </div>
      )
    )}
</div>


                </div>
              </div>
            </div>



          </form>
        </div>
      </div>
    </div>


    // </Modal>



  );
}
