import { marketConstants } from '../_constants';
import { alertActions } from './alert.actions';
import {
    APIcallFunction,
    //  headerForPublicAPI, logoutFunction,
    headerForPrivateAPI, APIcallFunctionForImage, headerForPrivateMediaAPI
} from '../_helpers';
export const marketActions = {
    createMarket,
    getMarketList,
    getAllMarket,
    updateMarket,
    updateMarketStatus,
    getMarketByMarketIdForAdmin,
    deleteMarket,
    uploadImageApi,
    // uploadImage2,
    uploadImageClear,
};

function createMarket(data, setCreateModal, marketPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getMarketList(marketPageRefresh));
                    setCreateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.CREATE_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.CREATE_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.CREATE_MARKET_FAILURE, error } }
}
function getMarketList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getMarketList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.GET_MARKET_LIST_REQUEST, market } }
    function success(market) { return { type: marketConstants.GET_MARKET_LIST_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.GET_MARKET_LIST_FAILURE, error } }
}

function updateMarket(data, navigate
    // , marketPageRefresh
) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(alertActions.success("Updated"));
             
     
               
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.UPDATE_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.UPDATE_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.UPDATE_MARKET_FAILURE, error } }
}

function updateMarketStatus(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateMarketStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getMarketList(userPageRefresh));
                    dispatch(alertActions.success("Market Status Update Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.UPDATE_MARKET_STATUS_REQUEST, market } }
    function success(market) { return { type: marketConstants.UPDATE_MARKET_STATUS_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.UPDATE_MARKET_STATUS_FAILURE, error } }
}

function getMarketByMarketIdForAdmin(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getMarketByMarketIdForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    console.log('marketACTION_____?', market);

                    dispatch(success(market));
                    // dispatch(getMarketList(userPageRefresh));
                    // dispatch(alertActions.success("Market Status Update Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST, market } }
    function success(market) { return { type: marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE, error } }
}

function deleteMarket(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getMarketList(userPageRefresh));
                    dispatch(alertActions.success("Market delete Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.DELETE_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.DELETE_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.DELETE_MARKET_FAILURE, error } }
}

function getAllMarket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.GET_ALL_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.GET_ALL_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.GET_ALL_MARKET_FAILURE, error } }
}

function uploadImageApi(filedata) {
    console.log('uploadImageuploadImage????', filedata);
    var formdata = new FormData();
    formdata.append('image', filedata);
    console.log('vvvvvvvvvvvvvvvvvvvv', formdata);
    const credentials = {
        header: headerForPrivateMediaAPI,
        method: "POST",
        body: formdata,
        endPoint: '/upload'
    }
    return dispatch => {
        // dispatch(request({ data }));
        APIcallFunctionForImage(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    // function request(market) { return { type: marketConstants.FILE_UPLOAD_STATUS_REQUEST, market } }
    function success(market) { return { type: marketConstants.FILE_UPLOAD_STATUS_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}

// function uploadImage2(filedata) {

//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlSWQiOjAsInVzZXJOYW1lIjoic3VwZXJBZG1pbiIsInVzZXJJZCI6IjY2MTY2NDFkY2ViNTllNDEwYmExMmQ5NCIsImlhdCI6MTcxMzIwMjQ1MCwiZXhwIjoxNzEzNDE4NDUwfQ.ethVqEQOVyfMv5O2iP7szzpWa38FyEbFOWwXJC4wWBQ");

//     var formdata = new FormData();
//     formdata.append("image", fileInput.files[0], "2.png");

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: formdata,
//       redirect: 'follow'
//     };

//     fetch("http://localhost:8804/api/upload", requestOptions)
//       .then(response => response.text())
//       .then(result => console.log(result))
//       .catch(error => console.log('error', error));

// }


function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: marketConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}


// function uploadImage(data) {
//     return dispatch => {
//         dispatch(request());
//         userService.uploadImage(data)
//             .then(
//                 uploadImage => {
//                     toast("Image Uploaded successfully.")
//                     dispatch(success(uploadImage));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.FILE_UPLOAD_STATUS_REQUEST } }
//     function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
//     function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

// }
