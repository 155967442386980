import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { IoMdCloseCircle } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
const ChangeTnxPasswordModel = ({ showModal2, setShowModal2 }) => {
    let dispatch = useDispatch();
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    let [eye1, seteye1] = useState(false);
    let [eye2, seteye2] = useState(false);
    let [eye3, seteye3] = useState(false);
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
        setError('');
        setSuccess('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmNewPassword) {
            setError('New passwords do not match');
            return;
        }

        let obj = {
            "oldPassword": formData?.oldPassword,
            "newPassword": formData?.newPassword,
            "confirmPassword": formData?.confirmNewPassword
        };

        dispatch(userActions.changetnxPassword(obj));
        setFormData({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        });
        setShowModal2(false);
        // setError('');
        // setSuccess('Password changed successfully');
    };

    const handleClose = () => {
        setShowModal2(false);
        setFormData({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        });
    };

    return (
        <div>
            {showModal2 && (
                <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
                <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <div className='flex justify-between'>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Change Tnx Password
                                        </h3>
                                        <IoMdCloseCircle className=' cursor-pointer text-xl text-[#F64401]' onClick={handleClose} />
                                    </div>
                                    <div className="mt-2">
                                        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
                                        {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">{success}</div>}
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-4">
                                                <label htmlFor="oldPassword" className="block text-gray-700 text-sm font-bold mb-2">Old Tnx Password *</label>
                                                <div className=' relative w-full'>

                                                    <input
                                                        type={eye1?"text":"password"}
                                                        id="oldPassword"

                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        value={formData.oldPassword}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {eye1?<FaEye  onClick={()=>seteye1((prev)=>!prev)} className=' absolute right-3 top-3'/>:<FaEyeSlash  onClick={()=>seteye1((prev)=>!prev)} className=' absolute right-3 top-3'/>}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="newPassword" className="block text-gray-700 text-sm font-bold mb-2">New Tnx Password *</label>
                                                <div className=' relative w-full'>
                                                <input
                                                    type={eye2?"text":"password"}
                                                    id="newPassword"
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={formData.newPassword}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                {eye2?<FaEye  onClick={()=>seteye2((prev)=>!prev)} className=' absolute right-3 top-3'/>:<FaEyeSlash  onClick={()=>seteye2((prev)=>!prev)} className=' absolute right-3 top-3'/>}
                                                </div>
                                              
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="confirmNewPassword" className="block text-gray-700 text-sm font-bold mb-2">Confirm New Tnx Password *</label>
                                                <div className=' relative w-full'>

                                                <input
                                                     type={eye3?"text":"password"}
                                                    id="confirmNewPassword"
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={formData.confirmNewPassword}
                                                    onChange={handleInputChange}
                                                    required
                                                    />
                                                     {eye3?<FaEye  onClick={()=>seteye3((prev)=>!prev)} className=' absolute right-3 top-3'/>:<FaEyeSlash  onClick={()=>seteye3((prev)=>!prev)} className=' absolute right-3 top-3'/>}
                                                    </div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <button
                                                    type="submit"
                                                    className="bg-[#F64401] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                >
                                                    Change Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
};

export default ChangeTnxPasswordModel;
